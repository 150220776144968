/* .react-calendar {
  max-width: 100%;
  background: white;
  border-radius: 30%;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  opacity: 0.7;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #91b6c6;
  color: white;
  border-radius: 50%;
}

@media only screen and (min-width: 1536px) {
  .react-calendar {
    font-size: 1.3em;
  }
  .react-calendar__month-view__weekdays {
    font-size: 1.3em;
  }
} */

/* new */

.react-calendar {
  width: 100%;
  border: none;
  padding: 1rem;
}
.react-calendar__month-view__days {
  text-align: center;
}

.react-calendar__viewContainer {
  text-align: center;
}

.react-calendar__tile:disabled {
  color: rgb(153, 152, 152);
}
.react-calendar__tile--active {
  background: #5b5b5b;
  color: #e7bdcb;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #5b5b5b;
  color: #e7bdcb;
}

.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus {
  background: #5b5b5b;
  color: #e7bdcb;
}

.react-calendar button {
  font-family: "Bahij TheSansArabic Light";
}
.react-calendar__tile {
  text-align: center;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile--now {
  background: white;
  color: black;
}

.react-calendar__tile--now:hover {
  background: white;
  color: black;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: "Bahij TheSansArabic Light";
}

@media screen and (max-width: 768px) {
  .react-calendar__tile {
    text-align: center;
    padding: 1%;
  }
}

@media screen and (max-width: 610px) {
  .react-calendar__navigation {
    flex-direction: column;
    margin-bottom: 6rem;
  }
  .react-calendar__month-view__days {
    display: flex;
    justify-content: center;
  }
  .react-calendar__navigation__label {
    padding: 1rem 0;
  }
}

.schedule span {
  margin: 0 0.1rem;
}
